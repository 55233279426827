import Vue from 'vue'
import App from './App.vue'


// External plugins

import router from './router'
import {store} from './store'
import VueHotkey from 'v-hotkey'
import vSelect from 'vue-select'
import VueToast from 'vue-toast-notification';

// Style

import '@/assets/css/tailwind.css'
import 'remixicon/fonts/remixicon.css'
import "vue-select/dist/vue-select.css";
import 'vue-toast-notification/dist/theme-sugar.css';



Vue.component('v-select', vSelect)
Vue.config.productionTip = false
Vue.use(VueToast);
Vue.use(VueHotkey)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
