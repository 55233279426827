<template>
    <div>
        <div class="text-white rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Новая граница города</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">WKT</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" laceholder="WKT" :value="propVillage">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Название</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" laceholder="WKT" v-model="village.name">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Город</label>
                <!-- <select class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" laceholder="WKT" v-model="village.city_id">
                    <option  v-for="city in cities" :key="city.id" :value="city.id">{{city.name_ru}}</option>
                </select> -->
                <v-select
                class="w-[270px] text-black bg-white"
                :options="cities"
                :reduce="city => city.id"
                v-model="village.city_id"
                label="name_ru"></v-select>
            </div>
            <div class="flex my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 py-1 px-2 cursor-pointer"><i class="ri-search-line"></i></div>
                <button class="bg-white text-black rounded-sm p-1 ml-auto" @click="$emit('update')">Отменить</button>
                <button class="bg-black/40 text-white rounded ml-2 p-1" @click="addVillages()">Сохранить</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['propVillage', 'addVilldage'],
    data() {
        return {
            village: {
                name: '',
                polygon: this.propVillage,
                city_id: ''
            },
            cities: []
        }
    },
    methods: {
        addVillages(){
            this.$http.post('addresses/villages', {
                name: this.village.name,
                polygon: this.propVillage,
                city_id: this.village.city_id
            })
            .then(res => {
                this.$emit('update')
                console.log(res);
            })
            .catch(err => {
                console.error(err);
            })
        }
    },
    watch: {
        addVilldage() {
            if (this.addVilldage) {
            this.$http.get('addresses/cities')
            .then(res =>{
                this.cities = res.data
                console.log(res.data)
            })
        }
        }
    },
}
</script>