import Vue from 'vue' 
import Router from 'vue-router'
import Map from '@/components/Map/CustomMap.vue'
import Login from '@/components/AuthLogin.vue';
import ForgotPassword from "@/components/ForgotPassword.vue"
import { isLoggedIn } from './auth';

Vue.use(Router) 

const router = new Router({ 
mode: 'history',
routes: [
    { 
      path: '/login', 
      name: 'auth-login', 
      component: Login 
    },
    { 
      path: '/', 
      name: 'Main', 
      component: Map,
      meta: { requiresAuth: true }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword
    }
  ] 
}) 

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isLoggedIn()) {
    if(to.path !== '/login'){
        next({ path: '/login' })
    }
  } else {
    next()
  }
})
export default router
