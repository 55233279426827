import deleteLocalStorage from '@/util/deleteLocalStorage'

export default function checkToken(axiosIns) {
    let data = JSON.parse(localStorage.getItem('refresh_token'))
    
	if(!data) return
    checkingTheDateAndTime()

    setInterval(() => {
        checkingTheDateAndTime()
    }, 60000)

	setInterval(() => {
		refreshToken(axiosIns)
	}, 900000)
}

function refreshToken(axiosIns) {
    let login_data = JSON.parse(localStorage.getItem('login_data'))

    axiosIns.post('v1/auth/login', login_data)
    .then((res) => {
        let userData = JSON.parse(localStorage.getItem('refresh_token'))
        userData.expDate = res.data.expDate
        userData.token = res.data.token
        let data = JSON.stringify(userData)
        localStorage.setItem('refresh_token', data)
        localStorage.setItem('user-token', res.data.token)
        axiosIns.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`
    })
    .catch((error) => {
        console.log(error);
    })
}


function checkingTheDateAndTime() {
    let data = JSON.parse(localStorage.getItem('refresh_token'))
    
    let dateStart = new Date(data.expDate)
    let dateEnd = new Date()

    let start = `${String(dateStart.getHours()).length == 2 ? dateStart.getHours() : '0' + dateStart.getHours()}:${String(dateStart.getMinutes()).length == 2 ? dateStart.getMinutes() : '0' + dateStart.getMinutes()}`
    let end = `${String(dateEnd.getHours()).length == 2 ? dateEnd.getHours() : '0' + dateEnd.getHours()}:${String(dateEnd.getMinutes()).length == 2 ? dateEnd.getMinutes() : '0' + dateEnd.getMinutes()}`  
    
    if(dateEnd.getDate() == dateStart.getDate()) {
        if(end >= start) {
            deleteLocalStorage()
        }
    }else {
        deleteLocalStorage()
    }
}
