import axios from '@/api/axios';

export async function addHouse(payload) {
    try {
        return await axios.post('/addresses/addresses', payload)
    } catch (error) {
        throw new Error(`API Request Error (addHouse): ${error}`)
    }
}

export async function getHomeTypes() {
    console.log('addresses/home-types');
    
    // try {
    //     return await axios.get('addresses/home-types')
    // } catch (error) {
    //     throw new Error(`API Request Error (getHomeType): ${error}`)
    // }
}

export async function getStreets(params) {
    try {
        return await axios.get('addresses/streets', {params: params})
    } catch (error) {
        throw new Error(`API Request Error (getStreets): ${error}`)
    }
}

export async function getAllDistricts(params) {
    try {
        return await axios.get(`v1/polygons/${params.bounds}/childs`)
    } catch (error) {
        throw new Error(`API Request Error (getAllDistricts): ${error}`)
    }
}