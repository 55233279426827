<template>
    <div class="text-white">
        <div class="text-white">
            <h1 class="text-2xl px-3 font-semibold">Группировать в зоны</h1>
        </div>
        <div class="my-4 px-3">
            <div class="mr-4 mb-2">Выбранные районы</div>
            <span v-if="district.name"><label class="border-[#3a383899] bg-[#3a383899] p-2 rounded-md" for="">{{district.name}}</label></span>
        </div>
        <div class="my-4 px-3 flex items-center justify-between">
            <label class="mr-5" >Зона</label>
            <v-select
            class="w-[270px] text-black bg-white relative"
            :options="zones"
            :reduce="zone => zone.id"
            v-model="district.group_id"
            label="name"></v-select>
        </div>
        <button class="buttonCustom ml-[66px]" @click="openModal()" >Редактор зон</button>
        <div class="flex justify-between my-4 px-3">
            <button class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="close()" >Отмена</button>
            <button class="buttonCustom" :disabled="isActive" :class="isActive ? 'disabled' : ''" @click="editZone()">Сохранить</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['village_id','zona', 'statusZone'],
    data() {
        return {
            zones: [],
            district: {},
            districtsIds: [],
            isActive: true,
            zoneId: ''
        }
    },
    watch: {
        zona(newValue) {
            this.zoneId = newValue
            this.getZones()
            this.isActive = true
            this.$http.get(`v1/polygons/child/${newValue}`)
            .then((res) => {
                this.district = res.data
                this.isActive = false
            })
            .catch((error) => {
                console.error(error);
            })
        },
        village_id(newValue) {
            if(newValue) {
                this.getZones()
            }
        },
        statusZone(value) {
            if(!value) {
                if(this.zoneId) {
                    this.isActive = false
                }else {
                    this.isActive = true
                }
            }else {
                this.isActive = true
            }
        }
    },
    methods: {
        getZones() {
            this.$http.get(`v1/polygons/${this.village_id}/zones`)
            .then(res => {
                this.zones = res.data
            })
        },
        openModal() {
            this.isActive = true
            this.$emit('zoneStatus')
        },
        addZone() {
            let postData = {
                name: this.name,
                village_id: this.village_id
            }
            this.$http.post('addresses/zones', postData)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
        },
        close() {
            this.zones = [],
            this.district = {},
            this.districtsIds = [],
            this.isActive = true
            this.$emit('close')
        },
        editZone() {
            this.isActive = true
            this.$http.put(`v1/polygons/zone/${this.district.group_id}/childgroup`, {zones:[this.district.id]})
            .then(() => {
                this.$toast.open({
                    message: 'Изменено зона',
                    type: 'success',
                    position: 'top-right'
                });
                this.close()
            })
            .catch((error) => {
                this.$toast.open({
                    message: `${error}`,
                    type: 'error',
                    position: 'top-right'
                });
            })
            .finally(() => {
                this.isActive = false
            })
        }
    }
}
</script>

<style scoped>
.buttonCustom {
    @apply border-[1px] border-white bg-white rounded-[3px] text-black p-1;
}
.disabled {
    @apply bg-black text-white border-black;
}
</style>