<template>
    <div>
        <div class="text-white">
            <h1 class="text-2xl px-3 font-semibold">Редактор зон</h1>
            <div class="flex">
                <div class="flex items-center ml-2" v-for="option in createEditStatus" :key="option.id" @click="statusCrud == 'create' ? getZone() : ''">
                    <input type="radio" :id="option.id" :value="option.id" v-model="statusCrud">
                    <label class="ml-2" :for="option.id">{{ option.name }}</label>
                </div>
            </div>
            <div v-if="statusCrud === 'create'" class="mx-3 mt-4">
                <label for="" class="mr-[54px]" >Название</label>
                <input @change="createFilter" class="border !rounded-md w-[200px] text-sm text-black px-2 py-1 h-[30px]" v-model="create.name" type="text">
            </div>
            <div v-if="statusCrud === 'edit'" class="mx-3 mt-4">
                <div class="flex">
                    <label for="" class="mr-[90px]">Зона</label>
                    <customSelect
                    :options="zones"
                    v-model="edit"
                    label="name"
                    class="text-black w-[200px]"
                    ></customSelect>
                </div>
                <div class="flex mt-5">
                    <label for="" class="mr-[7px]">Переименовать</label>
                    <input type="text" @change="editFilter()" v-model="edit.name" class="border !rounded-md w-[200px] text-sm text-black px-2 py-1 h-[30px]">
                </div>
            </div>
            <div class="flex justify-between my-4 mx-3">
                <button class="buttonCustom" @click="close()" >Отмена</button>
                <button :class="active ? 'disabled' : ''" :disabled="active"  class="buttonCustom" @click="save(statusCrud)" >Сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
import customSelect from '@/components/custom/customSelect/index.vue'
export default {
    props: ['village_id'],
    components: {
        customSelect,
    },
    data() {
        return {
            createEditStatus: [
                { id: 'create', name: 'Создание' },
                { id: 'edit', name: 'Редактирование' }
            ],
            statusCrud: 'create',
            zones: [],
            create: {
                name: ''
            },
            edit: {
                id: '',
                name: ''
            },
            saves: {
                create: () => this.addZone(),
                edit: () => this.editZone()
            },
            active: true
        }
    },
    methods: {
        getZone() {
            this.$http.get(`v1/polygons/${this.village_id}/zones`)
            .then((res) => {
                this.zones = res.data
            })
            .catch((error) => {
                console.log(error);
            })
        },
        close() {
            this.statusCrud = 'create',
            this.zones = [],
            this.create = {
                name: ''
            },
            this.edit = {
                id: '',
                name: ''
            },
            this.$emit('close')
        },
        createFilter() {
            if(this.create) {
                this.active = false
            }else {
                this.active = true
            }
        },
        editFilter() {
            if(this.edit.name) {
                this.active = false
            }else {
                this.active = true
            }
        },
        addZone() {
            this.active = true
            this.$http.post(`v1/polygons/${this.village_id}/zone`, this.create)
            .then(() => {
                this.$toast.open({
                    message: `Зона успешно создана`,
                    type: 'success',
                    position: 'top'
                });
                this.close()
            })
            .catch((error) => {
                this.$toast.open({
                    message: `${error}`,
                    type: 'error',
                    position: 'top'
                });
            })
            .finally(() => {
                this.active = false
            })
        },
        editZone() {
            this.active = true
            this.$http.patch(`v1/polygons/zone/${this.edit.id}`, this.edit)
            .then(() => {
                this.$toast.open({
                    message: `Зона успешно изменено`,
                    type: 'success',
                    position: 'top'
                });
                this.close()
            }) 
            .catch((error) => {
                this.$toast.open({
                    message: `${error}`,
                    type: 'error',
                    position: 'top'
                });
            }) 
            .finally(() => {
                this.active = false
            })
        },
        save(param) {
            this.saves[param]?.()
        }
    }
}
</script>

<style scoped>
.buttonCustom {
    @apply border-[1px] border-white bg-white rounded-[3px] text-black p-1;
}
.disabled {
    @apply bg-black text-white border-black;
}
</style>