<template>
    <div class="flex justify-center h-screen">
        <div class="bg-[#F8F8F8] w-4/5 hidden lg:block">
            <div class="absolute mx-8 mt-3 block">
                <a href="#" target="_self" class="brand-logo ml-1 gram-logo">
                    <h2 class="font-semibold text-2xl text-[#042AEC]">Gram</h2>
                </a>
            </div>
            <div class="h-full flex items-center justify-center">
                <div class="">
                    <img src="../assets/img/forgot-password-v2.svg" alt="img" class="mx-auto md:w-4/5 lg:w-11/12">
                </div>
            </div>
        </div>
        <div class="container lg:w-2/5 px-16 sm:w-full md:w-3/5">
            <div class="relative top-1/3 tracking-tighter ">
                <form @submit.prevent="" class="text-[#6E6B7B] text-sm">
                    <h2 class="text-[#5e5873] leading-6 text-xl mb-4 font-normal ">Забыли пароль? 🔒</h2>
                    <p class=" text-base font-normal mb-5">
                        {{ infoText }}
                    </p>
                    <div v-if="nextStep">
                        <label  class="my-3 tracking-tighter">Логин</label><br>
                        <input class="border-[1px] border-solid w-full h-[38px] p-2 mt-1"
                            style="border-radius: 0.375rem;" required
                            v-model="login" placeholder="Логин">
                        <button type="submit" @click="getCode()"
                            class="w-full border mt-4 rounded-md text-[14px] bg-[#005eff] p-3 text-white hover:shadow-xl shadow-[#005eff]">Получить
                            код
                        </button>
                        <div class="flex justify-center text-[#005eff] items-center mt-5">
                            <div class="text-[#005eff] text-[16px] cursor-pointer flex justify-center items-center"
                                @click="backToLogin()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-chevron-left">
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                                Назад
                            </div>
                        </div>
                    </div>
                    <div v-if="!nextStep">
                        <label class="my-3 tracking-tighter">Код</label><br>
                        <input class="border-[1px] border-solid w-full h-[38px] p-2 mt-1"
                            style="border-radius: 0.375rem;" required
                            v-model="smsCode" placeholder="XXXXXX">
                        <button  @click="getPassword()"
                            class="w-full border mt-4 rounded-md text-[14px] bg-[#005eff] p-3 text-white hover:shadow-xl shadow-[#005eff]">
                            Востановить пароль
                        </button>
                        <div class="flex justify-center text-[#005eff] items-center mt-5">
                            <div class="text-[#005eff] text-[16px] cursor-pointer flex justify-center items-center"
                                @click="backToLogin()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-chevron-left">
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                                Назад
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ForgotPassword',
    data() {
        return {
            login: undefined,
            password_changes_id: undefined,
            nextStep: true,
            smsCode: undefined,
            infoText: 'Введите свой логин, и мы вышлем вам инструкции по сбросу пароля'
        }
    },
    methods: {
        getCode() {
            this.$http.post(`auth/password-changes?login=${this.login}`)
                .then(res => {
                    this.password_changes_id = res.data.password_changes_id
                    console.log(res.data.password_changes_id, "0");
                    this.nextStep = false
                    this.infoText = 'Мы отправили смс код на ваш номер телефона. Введите его чтобы продолжить'
                    this.$toast.open({
                        message: res.data.message,
                        type: 'success',
                        position: 'top'
                    });
                }).catch(err => {
                    console.log(err, "0");
                    this.$toast.open({
                        message: err.response.data.errors.login[0],
                        type: 'error',
                        position: 'top'
                    });
                })
        },
        getPassword() {
            this.$http.post(`auth/password-changes/2?password_changes_id=${this.password_changes_id}&sms_code=${this.smsCode}`)
                .then(res => {
                    console.log(res.data, "1");
                    this.nextStep = true
                    this.$toast.open({
                        message: res.data.message,
                        type: 'success',
                        position: 'top'
                    });
                }).catch(err => {
                    console.log(err, "1");
                    this.$toast.open({
                        message: err.response.data.errors,
                        type: 'error',
                        position: 'top'
                    });
                })
        },
        backToLogin() {
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped>
button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button !important;
    background-image: none !important;
    background-color: rgb(0 94 255 / var(--tw-bg-opacity));
    ;
}
</style>