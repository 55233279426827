import { render, staticRenderFns } from "./FormHome.vue?vue&type=template&id=f96d674c&scoped=true"
import script from "./FormHome.vue?vue&type=script&lang=js"
export * from "./FormHome.vue?vue&type=script&lang=js"
import style0 from "./FormHome.vue?vue&type=style&index=0&id=f96d674c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f96d674c",
  null
  
)

export default component.exports