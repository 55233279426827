<template>
    <div style="width: 510px;">
        <h1 class="text-2xl px-3 font-semibold text-white">Маршрут</h1>
        <div class="flex justify-between my-4 px-3">
            <label class="text-sm text-white">Тип транспорта</label>
            <v-select
            class="w-[350px] text-black bg-white"
            placeholder="Тип транспорта"
            :options="typeTransport"
            :reduce="options => options.id"
            label="name"
            disabled
            ></v-select>
        </div>
        <div class="flex justify-between my-4 px-3">
            <label class="text-sm text-white">Стратегия</label>
            <v-select
            class="w-[350px] text-black bg-white"
            placeholder="Стратегия"
            :options="strategy"
            :reduce="options => options.id"
            label="name"
            disabled
            ></v-select>
        </div>
        <div v-if="dataRouterPath.length >= 1" class="flex items-center justify-between">
            <div class="overflow-auto h-24 w-full ml-[20px]">
                <div v-for="(markers, index) in dataRouterPath" :key="index" class="flex p-1 items-center">
                    <i :class="(++index == 1) ? 'green' : (index == dataRouterPath.length ? 'red' : 'orange')">{{ index }}</i>
                    <green class="pl-3" v-if="index == 1"/>
                    <red class="pl-3" v-else-if="index == dataRouterPath.length"/>
                    <orange class="pl-3" v-else/>
                    <input v-if="!markers.village" class="ml-3 h-5 w-3/5" type="text" disabled v-model="markers.coords">
                    <div v-else class="w-3/5 ml-3 items-center flex">
                        <button class="bg-[#cecece] rounded-[2px] h-[18px] text-[9px] w-1/3" disabled>{{ markers.village }}</button>
                        <input  type="text" disabled class="h-5 w-2/3" v-model="markers.street">
                    </div>
                    <button @click="copyCoords(markers)" class="buttonCopy bg-[#bbb9b9] h-[18px] rounded-r-[5px] p-1"><img src="@/assets/img/file-copy.svg" alt="" srcset=""></button>
                    <button @click="deleteCoords(markers.id)" class="ml-5" ><img src="@/assets/img/cross-rounded-red.png" alt="" srcset=""></button>
                </div>
            </div>
            <button v-if="dataRouterPath.length >= 1" class="ml-5" @click="showReturnPath" :disabled="!markerShowReturnPath" title="Показать обратный путь"><img src="@/assets/img/swap.png" alt="" srcset=""></button>
        </div>
        <div class="my-4 px-3">
            <v-select
            v-if="!village"
            class="w-[485px] text-black bg-white"
            placeholder="Введите населенный пункт..."
            :options="polygons"
            v-model="village"
            label="name"
            ></v-select>
            <div v-if="village" class="flex items-center" >
                <button @dblclick="village = null" class="bg-[#bbb9b9] h-[40px] w-[157px] rounded-l-lg p-1">{{ village.name }}</button>
                <v-select
                    class="w-[485px] text-black bg-white"
                    placeholder="Введите координаты или адрес..."
                    v-model="searchData"
                    :options="address"
                    @keyup.enter.stop="searchAdress"
                    :filterable="false"
                    @search="searchAdress"
                    label="name"
                    >
                    <template v-slot:no-options>
                        Введите координаты или адрес...
                    </template>
                </v-select>
            </div>
        </div>
        <div class="flex items-center justify-between my-4 px-3">
            <p class="text-[#ffff]">Позиция новой точки</p>
            <v-select
            class="w-[300px] text-black bg-white"
            v-model="numberPositon"
            :options="numberPositonData"
            :reduce="options => options.id"
            label="name"
            ></v-select>
        </div>
        <div class="flex justify-between my-4 px-3">
            <div>
                <ul class="text-[#ffff] text-xs mt-10">
                    <li>Расстояние: {{ parameterDistance.distance ?  parameterDistance.distance : '' }}</li>
                    <li>Время: {{ parameterDistance.time ?  parameterDistance.time : '' }}</li>
                    <li>С учетом пробок: {{ parameterDistance.taking ?  parameterDistance.taking : '' }}</li>
                    <li>По городу: {{ parameterDistance.aroundTheCity ?  parameterDistance.aroundTheCity : '' }}</li>
                    <li>Междугород: {{ parameterDistance.betweenCities ?  parameterDistance.betweenCities : '' }}</li>
                    <li>Холостой пробег: {{ parameterDistance.idleMileage ?  parameterDistance.idleMileage  : '' }}</li>
                    <li>Наценка на дорогу: {{ parameterDistance.еxtraChargeForTheRoad ?  parameterDistance.еxtraChargeForTheRoad : '' }}</li>
                    <li>Комментарий {{ parameterDistance.comment ?  parameterDistance.comment : '' }}</li>
                </ul>
            </div>
            <div class="grid grid-cols-1 gap-1 content-between" >
                <div class="flex items-center">
                    <CustomButton @click="addMarkerToTheMap" class=" !ml-0 h-8 hover:bg-[#E6E6E6]"  :class="!textValidete ? '!bg-black !border-[#000] !text-[#fff]' : ''" :displayText="'Добавить'" :disabled="!textValidete"/>
                    <CustomButton @click="addCenterMarkerVillage" class="hover:bg-[#E6E6E6] !ml-4 h-8 !w-40" :disabled="!village" :class="!village ? '!bg-black !border-[#000] !text-[#fff]' : ''" :displayText="'Добавить центр н/п'" />
                </div>
                <i class="text-red-50 text-sm ml-10 mt-20">Посмотреть маршрут на:</i>
                <div class="flex">
                    <CustomButton @click="openInAnotherGoogle('https://www.google.com/maps/dir/')" class="hover:bg-[#E6E6E6] !ml-10 h-8 !w-[100px] !text-xs" :displayText="'Google Maps'" title="Google Maps"/>
                    <CustomButton @click="openInAnotherYandex(`https://yandex.tj/maps/10322/${village.city_name}/`)" class="hover:bg-[#E6E6E6] !ml-1 h-8 !w-[100px] !text-xs" :displayText="'Yandex Maps'" title="Yandex Maps"/>
                    <CustomButton @click="openInAnother2GIS(`https://2gis.ru/directions/points/`)" class="hover:bg-[#E6E6E6] !ml-1 h-8 !w-[50px] !text-xs" :displayText="'2GIS'" />
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import green from '@/components/icon/pinGreen.vue'
import orange from '@/components/icon/pinOrange.vue'
import red from '@/components/icon/pinRed.vue'
import CustomButton from '../elements/CustomButton.vue';
export default {
    props: ['dataRouterPath','villageData','polygons', 'is_number', 'parameterDistance'],
    components: {
        green,
        orange,
        red,
        CustomButton
    },
    data() {
        return {
            village: null,
            address: [],
            valueAddress: null,
            searchData: null,
            numberPositon: 1,
            pathsGoogle: {
                url: '',
                origin: '',
                destination: '',
                waypoints: '',
            },
            pathsYandex: {
                url: '',
                mapCenter: '',
                mode: 'mode=routes',
                rtt: 'rtt=auto',
                z: 'z=13',
                urls: ''
            },
            paths2GIS: {
                url: '',
                points: '',
                mapCenter: ''
            },
            numberPositonData: [{id: 1, name:1}],
            typeTransport: [
                {id: 1, name: 'Легковые'},
                {id: 2, name: 'Грузовые (от 5 тонн)'},
                {id: 3, name: 'Мотоциклы'}
            ],
            strategy: [
                {id: 1, name: 'Самый быстрый'},
                {id: 2, name: 'Оптимальный'},
                {id: 3, name: 'Самый короткий'}
            ],
        }
    },
    methods: {
        copyCoords(marker) {
            let coords = `${marker.coords[0] + ', ' + marker.coords[1]}`
            navigator.clipboard.writeText(coords)
        },
        deleteCoords(id) {
            let dataMarker = this.dataRouterPath.filter((e) => e.id !== id)
            this.$emit('deleteMarkerRouterPath', dataMarker)
            let lengthData = dataMarker.length
            this.numberPositon = ++lengthData
            this.numberPositonData = []
            for (let index = 1; index <= lengthData; index++) {
                this.numberPositonData.push({id:index, name: index})
            }
        },
        showReturnPath() {
            let data = []
            this.dataRouterPath.forEach((e) => data.unshift(e));
            this.$emit('showReturnPath', data)
            data = []
        },
        openInAnotherGoogle(url) {
            this.pathsGoogle.url = url
            this.dataRouterPath.forEach((e, index) => {
                if(index == 0) {
                    this.pathsGoogle.origin = `?api=1&origin=${e.coords[0]},${e.coords[1]}`
                }else if(this.dataRouterPath.length - 1 == index) {
                    this.pathsGoogle.destination += `&destination=${e.coords[0]},${e.coords[1]}`
                }else {
                    this.pathsGoogle.waypoints += `&waypoints=${e.coords[0]},${e.coords[1]}|`
                }
            })
            window.open(`${this.pathsGoogle.url}${this.pathsGoogle.origin}${this.pathsGoogle.destination}${this.pathsGoogle.waypoints}`,  '_blank')
        },
        openInAnotherYandex(url) {
            this.pathsYandex.url = url
            this.pathsYandex.mapCenter = `${this.village.lat},${this.village.lng}`
            this.dataRouterPath.forEach((e, index) => {
                if(index === 0) {
                    this.pathsYandex.urls += `${e.coords[0]},${e.coords[1]}`
                }else {
                    this.pathsYandex.urls += `~${e.coords[0]},${e.coords[1]}`;
                }
            })
            window.open(`${this.pathsYandex.url}?${this.pathsYandex.mapCenter}&${this.pathsYandex.mode}&rtext=${this.pathsYandex.urls}&${this.pathsYandex.rtt}&ruri=~~&${this.pathsYandex.z}`, '_blank');
        },
        openInAnother2GIS(url) {
            this.paths2GIS.url = url
            this.paths2GIS.mapCenter = `${this.village.lng}%2C${this.village.lat}/13`
            this.dataRouterPath.forEach((e, index) => {
                if(index == 0) {
                    this.paths2GIS.points += `${e.coords[1]}%2C${e.coords[0]}`;
                }else {
                    this.paths2GIS.points += `%7C${e.coords[1]}%2C${e.coords[0]}`;
                }
            })
            window.open(`${this.paths2GIS.url}${this.paths2GIS.points}?m=${this.paths2GIS.mapCenter}`, '_blank');
        },
        addMarkerToTheMap() {
            let data = this.dataRouterPath
            data.push({id: this.numberPositon, coords:[this.searchData.lat, this.searchData.lng], village: this.village.name, street: this.searchData.search})
            this.$emit('addMarkerToTheMap', data)
            ++this.numberPositon
            this.numberPositonData = []
            for (let index = 1; index <= this.numberPositon; index++) {
                this.numberPositonData.push({id:index, name: index})
            }
            this.searchData = null
        },
        searchAdress(value) {
            if(!value) return
            let data = {
                search: value,
                village_id: this.village.id
            }
            setTimeout(() => {
                this.$http.post('directory/search-addresses', data)
                .then((res) => {
                    this.address = res.data
                })
                .catch((error) => {
                    console.log(error);
                })
            }, 10)
        },
        addCenterMarkerVillage() {
            let data = this.dataRouterPath
            data.push({id: this.numberPositon, coords:[this.village.lat, this.village.lng]})
            this.$emit('addCenterMarkerVillage', data)
            let lengthData = data.length
            this.numberPositon = ++lengthData
            this.numberPositonData = []
            for (let index = 1; index <= lengthData; index++) {
                this.numberPositonData.push({id:index, name: index})
            }
        },
    },
    computed: {
        markerShowReturnPath() {
            return this.dataRouterPath.length > 1 ? true : false
        },
        textValidete() {
            return this.searchData ? true : false
        }
    },
    watch: {
        villageData(newValue) {
            this.village = newValue
            
        },
        is_number(newValue) {
            if(newValue) {
                let lengthData = this.dataRouterPath.length
                this.numberPositon = ++lengthData
                this.numberPositonData = []
                for (let index = 1; index <= lengthData; index++) {
                    this.numberPositonData.push({id:index, name: index})
                }
            }
        }
    }
}
</script>

<style scoped>
.green {
    color: #33c19e;
}
.orange {
    color: #f6980f;
}
.red {
    color: #cf0f04;
}
.buttonCopy:active {
    @apply bg-slate-400
}
</style>