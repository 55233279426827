<template>
<div class="relative max-w-[636px]">
    <input
    @focus="showOptions"
    @input="filterOptions"
    v-model="selectedLabel"
    class="input"
    />
    <svg
    @click="showList = !showList"
    :class="showList ? 'arrow-svg show' : 'arrow-svg'"
    width="17"
    height="10"
    viewBox="0 0 17 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.78866 6.48554C8.18013 6.88174 8.81987 6.88174 9.21134 6.48554L14.9208 0.707024C15.3053 0.317927 15.9336 0.317927 16.318 0.707024C16.696 1.08957 16.696 1.70496 16.318 2.0875L9.21134 9.28006C8.81987 9.67627 8.18013 9.67626 7.78866 9.28006L0.681993 2.0875C0.304022 1.70496 0.304022 1.08957 0.681995 0.707024C1.06645 0.317927 1.69471 0.317927 2.07916 0.707024L7.78866 6.48554Z"
        fill="#343434"
    />
    </svg>
    <svg
    v-if="selectedLabel"
    @click="clearSelection"
    class="plus"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3137 10.8686L17.4049 5.77746L18.5362 6.90883L13.4451 12L18.5362 17.0912L17.4049 18.2225L12.3137 13.1314L7.22254 18.2225L6.09117 17.0912L11.1823 12L6.09117 6.90883L7.22254 5.77746L12.3137 10.8686Z"
        fill="#343434"
    />
    </svg>
    <ul v-show="showList" class="options-list">
    <span v-if="options.length">
        <li
        v-for="option in filteredOptions"
        :key="option.id"
        @click="selectOption(option)"
        :class="{ active: option.id === value }"
        >
        {{ option.name }}
        </li>
    </span>
    <span v-else class="no-data">Данных нет!</span>
    </ul>
</div>
</template>

<script>
export default {
name: 'CustomSelect',
props: ['value', 'options'],
data() {
    return {
        showList: false,
        selectedLabel: '',
        inputText: '',
    };
},
computed: {
    filteredOptions() {
    if(this.inputText.toLowerCase()) {
        return this.options.filter(option =>
            option.name.toLowerCase().includes(this.inputText.toLowerCase())
        );
    }else {
        return this.options
    }
    },
},
watch: {
    value(newVal) {
        let selectedOption = this.options.find(option => option.id == newVal.id);
        this.selectedLabel = selectedOption ? selectedOption.name : '';
    },
    options(newVal) {
        const selectedOption = newVal.find((option) => option.id == this.value.id);
        this.selectedLabel = selectedOption ? selectedOption.name : '';
    }
},
methods: {
        showOptions() {
            this.showList = true;
        },
        filterOptions() {
            this.inputText = this.selectedLabel;
        },
        selectOption(option) {
            this.$emit('input', option);
            this.selectedLabel = option.name;
            this.showList = false;
        },
        clearSelection() {
            this.selectedLabel = '';
            this.$emit('input', '');
        },
    },
};
</script>

<style scoped>
.input {
    @apply max-w-[636px] w-full max-h-[30px] outline-none h-full pl-[20px] pr-[60px] py-[17px] rounded-[10px] leading-[24.5px] border border-[#D9D9D9] font-normal text-[16px] sm:text-lg;
}
.arrow-svg {
    @apply absolute right-4 top-[15px] duration-500;
}
.arrow-svg.show {
    @apply rotate-180;
}
.plus {
    @apply absolute right-[40px] top-[7px];
}
.options-list {
    @apply absolute max-w-[636px] max-h-[200px] overflow-auto w-full py-[9px] border z-20 bg-[#fff] rounded-[10px] leading-[21px] text-sm;
}
li {
    @apply text-[16px] px-[20px] py-[12px] cursor-pointer hover:bg-[#F5F5F6];
}
.active {
    @apply bg-[#F5F5F6];
}
.no-data {
    @apply ml-[20px];
}
</style>