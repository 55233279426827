<template>
    <div class="bg-black/70  absolute z-[400] rounded-[8px]" :class="!panel ? 'hidden' : `${classSetting}` ">
        <div v-if="isActive" @click="$emit('close')" :class="`${classMarginleftIcon}`" class="text-white text-2xl cursor-pointer w-10"><i class="ri-close-line"></i></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        panel: {
            type: Boolean,
        },
        classSetting: {
            type: String,
            default: 'top-[90px] right-0'
        },
        classMarginleftIcon: {
            type: String,
            default: 'ml-auto'
        },
        isActive: {
            type: Boolean,
            default: true
        }
    }
}
</script>