import encodePolyline from './encodePolyline'
import decodePolyline from './decodePolyline'

function converterToGeojsonOrPolygon(data) {
    return typeof(data) == 'string' ? converterGeojson(data) : converterWKT(data)
} 

function converterGeojson(data) {
    return data.replace(/POLYGON\(\(|\)\)/g, "")
    .split(",")
    .map( e => e.split(" ").map(Number))
}

function converterWKT(data) {
    return 'POLYGON((' + data.map(e => `${e[0]} ${e[1]}`).join(',') + '))'
}


export default {converterToGeojsonOrPolygon, encodePolyline, decodePolyline}

// Пример использование encodePolyline and decodePolyline
// const geojson = {
//     type: "LineString",
//     coordinates: [
//         [30.5, 50.5],
//         [30.51, 50.51],
//         [30.52, 50.52]
//     ]
// };

// const encode = encodePolyline(geojson.coordinates)
// console.log(encode);
// const decode = decodePolyline(encode);
// console.log(decode);




