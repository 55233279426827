<template>
  <div v-hotkey="keymap">
    <l-map id="map" style="height: 100vh;" ref="map" :zoom="zoom" :center="center" :maxZoom="20"
      @update:zoom="zoomUpdated" :options="{ zoomControl: false }"   @moveend=" ''"
      @contextmenu="handle($event)">
      <l-routing-machine ref="route" :waypoints="waypoints" ></l-routing-machine>
      <l-marker @click="isMarkerActive = false" v-if="isMarkerActive" :lat-lng="center">
      </l-marker>
      <l-marker @click="searchAddresWithCoor" v-if="showContext.lat" :lat-lng="showContext">
        <l-icon >
          <div class="w-[80px] rounded-md border-2 border-black text-center p-[5px] bg-white">
            Что здесь?
          </div>
        </l-icon>
      </l-marker>
      <div class="search_division" style="position: fixed; top: 0; left: 15px;">
        <div class="container ml-0">
          <div class="w-auto mt-[10px]">
            <v-select
              class="w-[270px] text-black bg-white"
              v-model="center"
              :options="divisions"
              label="displayName"
              :get-option-label="(option) => option.displayName"
              @search="searchAddres"
              :filter="fuseSearch"
              @input="changeStreetId"
            >
              <template v-slot:no-options>
                Данные отсутствуют в базе
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="search" style="position: fixed; top: 0; right: 0; ">
        <div class="container ml-0">
          <div class="w-auto mt-[20px] ml-[15px]">
            <search-select :searchAddress="searchAddress" @setWaypoints="setWaypoints" :isMarkerActive="isMarkerActive" @handleChangeDataCenter="handleChangeDataCenter"></search-select>
          </div>
        </div>
      </div>
      <l-tile-layer :url="url" :options="{ maxZoom: 20 }" />


      <div v-if="map?.mapObject">
        <markerAddressAndFast @addEntranceActiveButton="addEntranceActiveButton" @editCoordsEntrance="editCoordsEntrance" @coordinateDragend="coordinateDragend" @openModalAddress="openModalAddress" :zoom="zoom" :map="map?.mapObject" :addresses="addresses" :fastAddresses="fastAddresses" ref="markerAddressAndFast"/>
      </div>


      <div v-if="map?.mapObject">
        <showLocality @coorModal="coorModal" :map="map?.mapObject" ref="showLocality" :options="polygons" @isActivePolygonNP="isActivePolygonNP" />
      </div>
      
      <div v-if="editZones">
          <polygonCustom :map="map.mapObject" v-model="latlng" :options="districts" ref="editPolygon" @open="editPolygonWithIndex"/>
      </div>

      <div v-if="editedZone">
        <zonaCustom :map="map.mapObject" ref="zonaСhoosing" :options="districts" v-model="zona"/>
      </div>

      <div v-if="addDistrict">
        <addCustomPolygon v-model="addDistrictsData" :map="map.mapObject" ref="addDistricts"/>
      </div>
      <div v-if="addDistrict">
        <showPolygon :map="map.mapObject" ref="showPolygon" :options="districts"/>
      </div>

      <div v-if="lineIsActive">
        <createLine :map="map.mapObject" ref="Line"/>
      </div>

      <div v-if="routerIsActive">
        <custom-router :map="map.mapObject" ref="customRouter" @dataRouter="dataRouter"></custom-router>
      </div>

    </l-map>
    <tool-bar-map class="flex" :routerIsActive="routerIsActive" :lineIsActive="lineIsActive" :addFastAddress="addFastAddress" 
      :addLocSpeedaddress="addLocSpeedaddress" :editStreets="editStreets" :editHomes="editHomes" :addHomeSteets="addHomeSteets" 
      :addDistrict="addDistrict" @createDistrict="createDistrict" :editedZone="editedZone" @removeDistricts="removeDistricts"
      :editZones="editZones" :editVillageCity="editVillageCity" :homeCenterPolygon="homeCenterPolygon" @zoomPlus="zoomPlus" 
      @zoomMinus="zoomMinus" @addHome="addHome" @addLocation="addLocation"
      @eyeAddreses="eyeAddreses" @addFast="addFast" @editRouter="editRouter" @lineCreate="lineCreate" @showDistricts="showDistricts"
      @editPolygon="editPolygon" @addStreet="addStreet" @eyeDistricts="eyeDistricts"
      @eyeFastAdress="eyeFastAdress" @editFast="editFast" @editZone="editZonesInDist" @styleMap="styleMap" @polygonDisabled="polygonDisabled" />
    <forms-panel :panel="selectHome" :isActive="false" style="right: 10px;">
      <form-home :addEntranceActive="addEntranceActive"  @clearEntranceActive="clearEntranceActive"  @addEntrance="addEntrance" @clearModel="selectHome = false, clearMarker()" @createEntrance="createEntrance" :coordinateDragendMarker="coordinateDragendMarker" :editHouse="editHouse" :selectHome="selectHome" :village_id="village_id"
        @update="getAddress" @close="selectHome = false"  ref="formHome"/>
    </forms-panel>
    <forms-panel :panel="editVillageCity" @close="close(), editPolygon()">
      <form-city :village="village" @undoСhanges="undoСhanges(village)" @close="update(), close()" />
    </forms-panel>
    <forms-panel :classSetting="'top-[100px] left-[10px]'" :panel="addDistrict" @close="createDistrict()">
      <form-district-add @close="createDistrict()" :village_id="village_id" :addDistrictsData="addDistrictsData"/>
    </forms-panel>
    <forms-panel :classSetting="'top-[100px] left-[10px]'" :panel="editDistrict" @close="close(), removeDistricts()">
      <form-district-edit @close="close()" @getAllDistricts="getAllDistricts" @removeDistricts="removeDistricts" :village_id="village_id" :districtData="districtData" :latlng="latlng"
        @cancellation="cancellationDistrict(), close()" />
    </forms-panel>
    <forms-panel :panel="editStreets" @close="close">
      <form-street :dist_id_street="dist_id_street" :editStreets="editStreets" :village_id="village_id"
        @close="editStreets = false" />
    </forms-panel>
    <forms-panel :classSetting="'top-[100px] left-[10px]'" :panel="addLoc" @close="addLocation">
      <form-add ref="formAdd" :coor="houseCoordinates" @addressAdded="addressAdded"
        :village_id="village_id" @addHouse="addHouse" @addLocation="addLocation"/>
    </forms-panel>
    <forms-panel :panel="addVilldage" @close="close">
      <form-villages :propVillage="propVillage" :addVilldage="addVilldage" @update="update" />
    </forms-panel>
    <forms-panel :panel="addFastAddress" @close="close(), closeFormAddress()">
      <form-address :fastAddressCoor="fastAddressCoor" :addFastAddress="addFastAddress" :addAny="addAny"
        @fastAddressAdded="fastAddressAdded" :outsidePolygon="outsidePolygon" :village_id="village_id"
        @addCircleMarker="addCircleMarker" @removeFastMarker="removeFastMarker" @update="update"
        @close="closeFormAddress" />
    </forms-panel>
    <forms-panel :panel="editFastAddress" @close="close">
      <form-address-edit :editFastHouse="editFastHouse" :editFastAddress="editFastAddress" :village_id="village_id"
        @change="fastAddressDataChange" @refresh="eyeFastAdress" @close="editFastAddress = false" />
    </forms-panel>
    <forms-panel :classSetting="'top-[70px] left-[10px]'" :panel="editedZone" @close="editZonesInDist(), close()">
      <form-zone @zoneStatus="zoneStatus" :statusZone="statusZone" :village_id="village_id" :zona="zona" @close="editZonesInDist(), close()"></form-zone>
    </forms-panel>
    <forms-panel :classSetting="'top-[390px] left-[10px]'" :panel="statusZone" @close="zoneStatus()">
      <formZoneCrud @close="zoneStatus()" :village_id="village_id"  />
    </forms-panel>
    <forms-panel :panel="editRoute" @close="close">
      <form-router :is_number="is_number" :parameterDistance="parameterDistance" @addCenterMarkerVillage="addCenterMarkerVillage" @addMarkerToTheMap="addMarkerToTheMap" @showReturnPath="showReturnPath" :dataRouterPath="dataRouterPath" :polygons="polygons" :villageData="villageData" @deleteMarkerRouterPath="deleteMarkerRouterPath"></form-router>
    </forms-panel>
    <div v-if="loading" class="fixed top-0 left-0 bg-[#00000099] w-full h-full z-[1001]">
      <div
        class="absolute top-[calc(50%-20px)] left-[calc(50%-20px)] w-[40px] aspect-square border-[4px] rounded-full border-r-black border-t-black border-b-black animate-spin">
      </div>
    </div>
  </div>
</template>

<script>
import ToolBarMap from './ToolBarMap.vue'

// Modals

import FormHome from './FormHome.vue'
import FormsPanel from './FormsPanel.vue'
import FormCity from './FormCity.vue'
import FormStreet from './FormStreet.vue'
import FormVillages from './FormVillages.vue'
import FormDistrictAdd from './FormDistrictAdd.vue'
import FormDistrictEdit from './FormDistrictEdit.vue'
import FormAdd from './FormAdd.vue'
import FormZone from "./FormZone.vue";
import FormAddress from './FormAddress.vue';
import FormAddressEdit from "./FormAddressEdit.vue";
import FormRouter from './FormRoute.vue'
import formZoneCrud from './form-zone-crud.vue'


// Libraries 

import { mapActions } from "vuex";
import { mapState } from 'vuex';
import { Icon } from 'leaflet';
import L from "leaflet";
import LRoutingMachine from "@/components//Map/LRoutingMachine.vue"
import {LMap, LTileLayer, LMarker, LIcon} from 'vue2-leaflet'


// style 

import '@/assets/leaflet.css'
import '@/assets/MarkerCluster.css';
import '@/assets/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'

// Custom

import polygonCustom from '@/components/custom/polygonCustom.vue'
import zonaCustom from '@/components/custom/zonaCustom.vue'
import addCustomPolygon from '@/components/custom/addCustomPolygon.vue'
import showPolygon from '@/components/custom/showPolygon.vue'
import SearchSelect from "../SearchSelect.vue";
import styleMap from '@/util/styleMap'
import createLine from '@/components/custom/createLine.vue'
import customRouter from '@/components/custom/routerCustom.vue'
import showLocality from '../custom/showLocality.vue'
import markerAddressAndFast from '@/components/custom/markerAddressAndFast.vue'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  components: {
    LMap,
    zonaCustom,
    showPolygon,
    addCustomPolygon,
    LTileLayer,
    polygonCustom,
    ToolBarMap,
    FormHome,
    LRoutingMachine,
    FormsPanel,
    FormCity,
    FormAdd,
    FormStreet,
    FormVillages,
    FormDistrictAdd,
    FormDistrictEdit,
    FormZone,
    LMarker,
    LIcon,
    FormAddress,
    FormAddressEdit,
    SearchSelect,
    FormRouter,
    createLine,
    customRouter,
    showLocality,
    markerAddressAndFast,
    formZoneCrud,
  },
  mixins: [styleMap],
  data() {
    return {
      // url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      url:'https://mtile.gram.tj/styles/basic-preview/{z}/{x}/{y}.png',
      zoom: 13,
      center: {
        id: 6,
        name: "Грам (Душанбе)",
        lng: "68.78299713",
        lat: "38.55816663"
      },
      lineIsActive: false,
      parameterDistance: {
          distance: null,
          time: null,
          taking: null,
          aroundTheCity: null,
          betweenCities: null,
          idleMileage: null,
          еxtraChargeForTheRoad: null,
          comment: null,
      },
      villageData: null,
      is_number: null,
      dataRouterPath: [],
      addLocSpeedaddress: null,
      showContext: {},
      addHomeSteets: false,
      editHomes: false,
      addDistrictsData: null,
      routerIsActive: false,
      zona: null,
      latlng: [],
      districtData: [],
      editVillageCity: false,
      homeCenterPolygon:  false,
      fastAddresses: [],
      selectHome: false,
      editStreets: false,
      editDistrict: false,
      map: null,
      waypoints: [],
      pageX: 0,
      pageY: 0,
      editZones: false,
      addLoc: false,
      dist_id_street: null,
      district_id: 0,
      village_id: 0,
      propVillage: [],
      outsidePolygon: true,
      addVilldage: false,
      addFastAddress: false,
      editFastAddress: false,
      isMarkerActive: false,
      editRoute: false,
      editedZone: false,
      godColotInDistric: '',
      addDistrict: false,
      editHouse: null,
      addAny: false,
      editFastHouse: null,
      houseCoordinates: [],
      fastAddressCoor: [],
      addresses: [],
      divisions: [],
      village: '',
      polygons: [],
      loadings: {
        coordinates: false,
        villages: false,
        addresses: false,
      },
      markerFastAddress: null,
      markerAddress: null,
      searchAddress: [],
      currentBounds: null,
      coordinateDragendMarker: null,
      addEntranceActive: false,
      statusZone: false
    }
  },
  mounted() {
    this.map = this.$refs.map
    if(localStorage.getItem('divisions')) this.center = JSON.parse(localStorage.divisions)
    this.getAllCoordinates()
  },
  computed: {
    loading() {
      for (const key in this.loadings) {
        if (Object.hasOwnProperty.call(this.loadings, key)) {
          const element = this.loadings[key];
          if (element) {
            return true
          }
        }
      }
      return false
    },
    keymap() {
      return {
        'esc': () => {
          this.removeLine(),
          this.removeRouter()
        }
      }
    },
    ...mapState('district', ['districts']),
  },
  methods: {
    zoneStatus() {
      if(this.statusZone) {
        this.statusZone = false
      }else {
        this.statusZone = true
      }
    },

    // Map edit language

    styleMap(name) {
      this.urls.forEach((e) => {
        if(e.id == name) {
          this.url = e.name
        }
      })
    },

    //  City boundaries edit

    coorModal(data) {
      let coords = data.vertices.map((e) => {return [e.coords[0], e.coords[1]]})
      data.vertices = coords
      this.village = ''
      this.village = data
    },

    editPolygon() {
      if(this.homeCenterPolygon && this.village_id > 0) {
        if(!this.editVillageCity) {
          this.editVillageCity = true
          this.$refs.showLocality.selectPolygon(this.village_id)
        }else {
          this.editVillageCity = false
          this.clearPolygonModal()
        }
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },

    clearPolygonModal() {
      this.$refs.showLocality.cancelEdit()
    },

    undoСhanges(village) {
      let polyUndoChanges = this.polygons.filter((e) => e.id == village.id)
      this.$refs.showLocality.undoСhanges(polyUndoChanges)
    },

    // Districts Edit Group New

    showDistricts() {
      if(this.homeCenterPolygon) {
        this.getAllDistricts(this.village_id)
        this.editZones = true
        setTimeout(() => {this.$refs.editPolygon.show()}, 0);
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },    
    fastAddressAdded(value) {
      this.fastAddresses.push(value);
    },
    addressAdded(value) {
      this.addresses.push(value)
    },

    eyeAddreses() {
      this.getAddress()
    },


    editZonesInDist() {
      if(this.homeCenterPolygon) {
        if(this.editedZone) {
          this.$refs.zonaСhoosing.clearPolygon()
          this.close()
        }else {
          this.editedZone = !this.editedZone
          setTimeout(() => {this.$refs.zonaСhoosing.show()}, 0);
        }
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },
    
    createDistrict() {
      if(this.homeCenterPolygon) {
        if(this.addDistrict) {
          let status = this.$refs.addDistricts.clearPolygon()
          if(status) {
            this.close()
            this.$refs.showPolygon.mainClear()
          }else {
            return 
          }
        }else {
          this.addDistrict = true
          this.getAllDistricts(this.village_id)
          setTimeout(() => {this.$refs.addDistricts.addDistricts(), this.$refs.showPolygon.show()}, 0);
        }
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },

    // Houses, streets edit home add home edit streets

    addHome() {
      if (this.village_id != 0) {
        if(!this.editHomes) {
          this.editHomes = true
          this.getAddress()
        }else {
          this.clearDistrict()
          this.selectHome = false
          this.editHomes = false 
          this.addresses = []
          this.fastAddresses = []
          this.$refs.markerAddressAndFast.deleteAllMarker()
        }
      }
      else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },

    clearMarker() {
      this.$refs.markerAddressAndFast.clearmarker()
    },
    
    createEntrance(data) {
      this.$refs.markerAddressAndFast.createEntrance(data)
    },

    addEntranceActiveButton() {
      if(this.addEntranceActive == false) {
        this.addEntranceActive = true
      }else {
        this.addEntranceActive = false
      }
    }, 

    addEntrance(data) {
      this.addEntranceActiveButton()
      this.$refs.markerAddressAndFast.activeMap(data)
    },

    editCoordsEntrance(data, coords) {
      this.$refs.formHome.editCoordsEntrance(data, coords)
    },

    clearEntranceActive() {
      this.$refs.markerAddressAndFast.clearEntranceActive()
    },

    getAddress() {
      this.loadings.addresses = true
      this.$http.get(`v1/points?bounds=${this.villageData.bounds}`)
        .then(res => {
          this.addresses = res.data
          if(this.editHomes) {
            this.$refs.markerAddressAndFast.handleMapEvent()
          }
        })
        .catch(err => {
          console.log(err);
        }).finally(() => {
          this.loadings.addresses = false
        })
    },

    addLocation() {
      if (this.village_id) {
        if(!this.addHomeSteets) {
          this.addLoc = true
          this.addHomeSteets = true
          this.getAddress()
          this.addHouse()
          this.$refs.markerAddressAndFast.fundataEntranceActive()
          this.$refs.formAdd.getStreet()
        }else {
          this.addresses = []
          this.addHomeSteets = false
          this.addLoc = false
          this.currentBounds = null
          this.$refs.markerAddressAndFast.fundataEntranceActive()
          this.$refs.markerAddressAndFast.clearmarker()
          if(this.markerAddress) this.markerAddress.remove()
        }
      } else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },


    addHouse() {
      const map = this.$refs.map.mapObject;

      map.on('click', (e) => {
      if(this.addHomeSteets) {
          let latlng = e.latlng;
    
          if (!this.markerAddress) {
            this.markerAddress = L.circleMarker(latlng).addTo(map);
    
            this.setAddressCoordinatesToProps();
          } else {
            this.markerAddress.setLatLng(latlng);
    
            this.setAddressCoordinatesToProps();
          }
        }
      })
    },

    addStreet() {
      if (this.village_id != 0) {
        if(!this.editStreets) {
          this.editStreets = true
          this.editDistrict = false
          this.addDistrict = false
          this.editorPolygon = false
          this.selectHome = false
          this.addLoc = false
          this.addVilldage = false
          this.addFastAddress = false
          this.editFastAddress = false
        }else {
          this.editStreets = false
        }
      } else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },

    openModalAddress(id) {
      this.selectHome = false
      this.$refs.formHome.editformHome(id.id)
      this.selectHome = true
    },
    coordinateDragend(e) {
      this.coordinateDragendMarker = e
    },
    addCircleMarker() {
      console.log('addCircleMarker');
      
      // document.querySelector('.leaflet-draw-draw-circlemarker').click()
      // this.addAny = false
    },

    //  Quick Addresses edit add
    editFast() {
      if(this.village_id != 0) {
        if(!this.addLocSpeedaddress) {
          this.selectHome = false
          this.editorPolygon = false
          this.editStreets = false
          this.addLocSpeedaddress = true
          this.addDistrict = false
          this.editDistrict = false
          this.addVilldage = false
          this.addFastAddress = false
          this.eyeFastAdress()
          this.getAddress()
        }else {
          this.addLocSpeedaddress = false
          this.fastAddresses = []
          this.addresses = []
          this.editFastAddress = false
        }
      }else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },
  
    eyeFastAdress() {
      this.$http.get(`map-api/fast-addresses/${this.village_id}`, { params: { bounds: this.currentBounds } })
        .then(res => {
          res.data.forEach((e) => {
            if(e.lat) this.fastAddresses.push(e)
          })
        })
        .catch(err => {
          console.log(err);
        })
    },

    selectFastHouse(id) {
      this.$http.get(`addresses/fast/addresses/${id}/edit`)
        .then((res) => {
          this.editFastHouse = res.data;
          let index = this.fastAddresses.findIndex(el => el.id === this.editFastHouse.id);
          this.fastAddresses[index].draggable = true;
        })
        .catch((error) => {
          console.log(error);
        })
      this.editorPolygon = false
      this.selectHome = false
      this.addDistrict = false
      this.editDistrict = false
      this.editStreets = false
      this.addLoc = false
      this.addVilldage = false
      this.addFastAddress = false
      this.editFastAddress = true
    },

    markerDragEndAddress(event) {
      this.editFastHouse.lat = event.target._latlng.lat
      this.editFastHouse.lng = event.target._latlng.lng
    },


    addFast() {
      if (this.village_id != 0) {
        if(!this.addFastAddress) {
          this.editorPolygon = false
          this.selectHome = false
          this.addDistrict = false
          this.editDistrict = false
          this.editStreets = false
          this.addLoc = false
          this.addVilldage = false
          this.addFastAddress = true
          this.editFastAddress = false
          this.fastAddress()
          this.eyeFastAdress()
        }else {
          this.addFastAddress = false
          if(this.markerFastAddress) this.markerFastAddress.remove()
          this.markerFastAddress = null,
          this.fastAddresses = []
        }
      }
      else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },

    closeFormAddress() {
      this.addFastAddress = false
      if(this.markerFastAddress) this.markerFastAddress.remove()
      this.markerFastAddress = null,
      this.fastAddresses = []
    },


    // Add strets or fast address
    fastAddress() {
      const map = this.$refs.map.mapObject;

      map.on('click', (e) => {
        if(this.addFastAddress || this.addHomeSteets) { 
          let latlng = e.latlng;
  
          if (!this.markerFastAddress) {
            this.markerFastAddress = L.circleMarker(latlng).addTo(map);
  
            this.setFastAddressCoordinatesToProps();
          } else {
            this.markerFastAddress.setLatLng(latlng);
  
            this.setFastAddressCoordinatesToProps();
          }
        }
      })
    },

    setFastAddressCoordinatesToProps() {
      let coor = [this.markerFastAddress._latlng.lat, this.markerFastAddress._latlng.lng];
      this.fastAddressCoor = JSON.stringify(coor);
    },
    setAddressCoordinatesToProps() {
      let coor = [this.markerAddress._latlng.lat, this.markerAddress._latlng.lng];
      this.houseCoordinates = JSON.stringify(coor)
    },
      

    // Router
    editRouter() {
      if(this.village_id != 0) {
        if(!this.routerIsActive) {
         this.routerIsActive = true
         this.editRoute = true
         setTimeout(() => {this.$refs.customRouter.showRouter()}, 0)
        }else {
         this.routerIsActive = false   
        //  this.$refs.customRouter.removeMarker()
         this.$refs.customRouter.removeRouter()
         this.$refs.customRouter.clearRouter()
          this.editRoute = false
        }
      }else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },

    deleteMarkerRouterPath(dataMarker) {
      this.$refs.customRouter.deleteMarker(dataMarker)
    },

    dataRouter(data,is_number) {
      this.dataRouterPath = []
      this.dataRouterPath = data[0].routerLine
      this.parameterDistance = data[0].parameterDistance
      this.is_number = is_number
    },
    showReturnPath(data) {
      this.$refs.customRouter.showReturnPath(data)
    },

    addMarkerToTheMap(data) {
      this.$refs.customRouter.addMarkerToTheMap(data)
    },

    addCenterMarkerVillage(data) {
      this.$refs.customRouter.addCenterMarkerVillage(data)
    },

    removeRouter() {
      if(this.routerIsActive) {
        this.routerIsActive = false
        this.$refs.customRouter.removeMarker()
        this.$refs.customRouter.removeRouter()
        this.$refs.customRouter.clearRouter()
        this.editRoute = false
      }
    },

    // Line
    lineCreate() {
      if(!this.lineIsActive) {
        this.lineIsActive = true
        setTimeout(() => {this.$refs.Line.showLine()}, 0);
      }else { 
        this.$refs.Line.clearLine()
        this.lineIsActive = false
      }
    },

    removeLine() {
        if(this.lineIsActive) {
          this.$refs.Line.clearLine()
          this.lineIsActive = false
        }
      },
    

    fastAddressDataChange(data) {
      this.editFastHouse[data.field] = data.value
    },

    // GET Divisions

    searchAddres(event) {
      if(event) {
        setTimeout(() => {
          let search = `?name=${event}`
          this.$http.get(`v1/city/search${search ? search : ''}`)
          .then((res) => {
            if(res.data) {
              this.divisions = res.data
            }else {
              this.divisions = []
            }
          })
        }, 0);
      }
    },

    fuseSearch() {
      return this.divisions;
    },


    changeStreetId(event) {
      if(event) localStorage.divisions = JSON.stringify(event)
    },

    // Localities

   getAllCoordinates() {
    this.loadings.coordinates = true
    this.$http.get('v1/polygons?lang=ru')
        .then(res => {
          if(res.data) {
            this.polygons = res.data
            this.$refs.showLocality.refreshPolygon()
          }
        })
        .catch(err => {
          console.log(err);
        }).finally(() => {
          this.loadings.coordinates = false
        })
    },
    polygonDisabled() {
      if(!this.homeCenterPolygon) {
        this.$refs.showLocality.createPolygon()
        this.homeCenterPolygon = true
      }else {
        this.villageData = null
        this.village_id = null
        this.homeCenterPolygon = false
        this.$refs.showLocality.deletePolygon()
      }
    },

    isActivePolygonNP(id) {
      if(this.editZones) {
        this.removeDistricts()
      }
      this.clearDistrict()
      this.villageData = id
      this.homeCenterPolygon = true
      this.makesTheArrayOfDistrictsEmpty()
      this.village_id = id.id
      this.getAllDistricts(id.id)
      if(this.editVillageCity) {
        this.editPolygon()
      }
    },

    // Address search and route

    handleChangeDataCenter(center, hasAddress) {
      this.isMarkerActive = hasAddress
      this.center.lng = center.lng
      this.center.lat = center.lat
    },
    async setWaypoints(waypoints) {
      this.$refs.route.removeRoute()
      this.waypoints = await waypoints
      console.log(this.waypoints);
      if (this.waypoints.length > 0) {
        this.$refs.route.add()
      }
    },

    searchAddresWithCoor() {
      this.$http.get(`https://msearch.gram.tj/nominatim/reverse?format=jsonv2&lat=${this.showContext.lat}&lon=${this.showContext.lng}`)
        .then(res => {
          this.searchAddress = res.data
          this.showContext = {}
          this.center = {
            lng: res.data.lon,
            lat: res.data.lat
          }
          this.isMarkerActive = true
        }).catch((err) => {
          console.log(err);
        })
    },

    openContextMenu(e) {
      this.$refs.menu.open(e);
      this.pageX = e.containerPoint.x;
      this.pageY = e.containerPoint.y;
    },

    // Right-click address search

    handle(event) {
      this.showContext = {
        lat: event.latlng.lat,
        lng: event.latlng.lng
      }
    },

    //map

    zoomUpdated(zoom) {
      this.zoom = zoom;
    },

    centerUpdated(center) {
      this.center = center;
    },
    zoomPlus() {
      this.zoom++
    },
    zoomMinus() {
      this.zoom--
    },

    // view n/a of their polygons

    eyeDistricts() {
      this.editZones = !this.editZones
    },

    // delete 
      
    close() {
      this.selectHome = false
      this.editorPolygon = false
      this.editStreets = false
      this.addLoc = false
      this.addDistrict = false
      this.editDistrict = false
      this.addVilldage = false
      this.addFastAddress = false
      this.editFastAddress = false
      this.editedZone = false
      this.editRoute = false
    },

    removeFastMarker() {
      this.eyeFastAdress()

      if (this.markerFastAddress) {
        this.markerFastAddress.remove();
        this.markerFastAddress = null;
        this.fastAddressCoor = '';
      }
    },

    removeDistricts() {
      this.editZones = !this.editZones
      this.$refs.editPolygon.clearPolygon()
      this.close()
    },
    cancellationDistrict() {
      this.$refs.editPolygon.cancelPolygon()
      this.removeDistricts()
      setTimeout(() => this.showDistricts(), 0)
    },

    clearDistrict() {
     this.addresses = []
     this.editHomes = false
    },


    // store
    ...mapActions(['updateMapInstance']),
    ...mapActions('district', ['sendCreationEvent', 'getAllDistricts', 'makesTheArrayOfDistrictsEmpty']),
    setMapInstanceToStore(map) {
      this.updateMapInstance(map);
    },
    
    // update

    update() {
      this.$router.go()
      let center = JSON.stringify([this.center.lat, this.center.lng])
      localStorage.setItem("center", center)
      localStorage.setItem("zoom", this.zoom)
    },
    
    
    // editPolygon
    
    editPolygonWithIndex(id) {
      this.editDistrict = false
      this.district_id = id
      this.$http.get(`v1/polygons/child/${id}`)
      .then((res) => {
        this.districtData = res.data
        this.editDistrict = true
      })
      .catch((error) => {
        console.log(error);
      })
    },
    
  },
}
</script>

<style>
.search {
  width: 400px;
  z-index: 9998;
}
.search_division {
  z-index: 9999;
}
.vs__search,
.vs__search:focus {
  border: none;
}
</style>