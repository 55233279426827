function encodePolyline(coordinates) {
    let encoded = '';
    let prevLat = 0;
    let prevLng = 0;

    for (let i = 0; i < coordinates.length; i++) {
        const [lng, lat] = coordinates[i];
        
        // Умножаем координаты на 1e5 и округляем
        const latE5 = Math.round(lat * 1e5);
        const lngE5 = Math.round(lng * 1e5);

        // Вычисляем разницу от предыдущих значений
        const dLat = latE5 - prevLat;
        const dLng = lngE5 - prevLng;

        // Обновляем предыдущие значения
        prevLat = latE5;
        prevLng = lngE5;

        // Добавляем к закодированной строке
        encoded += encodeSignedNumber(dLat) + encodeSignedNumber(dLng);
    }

    return encoded;
}

function encodeSignedNumber(num) {
    let sgnNum = num << 1;
    if (num < 0) {
        sgnNum = ~sgnNum;
    }
    return encodeNumber(sgnNum);
}

function encodeNumber(num) {
    let encoded = '';
    while (num >= 0x20) {
        encoded += String.fromCharCode((0x20 | (num & 0x1f)) + 63);
        num >>= 5;
    }
    encoded += String.fromCharCode(num + 63);
    return encoded;
}

export default encodePolyline