<template>
<div class="text-white" >
    <h1 class="text-2xl px-3 font-semibold">Создание района</h1>
    <div class="my-4 px-3 flex justify-between items-center">
        <label class="mr-3" >Название <i style="color: red;">*</i></label>
        <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Название" v-model="district.name">
    </div>
    <div class="my-4 px-3 flex justify-between items-center">
        <label>Зона <i style="color: red;">*</i></label>
        <v-select
        class="w-[270px] text-black bg-white"
        :options="zones"
        :reduce="zone => zone.id"
        v-model="district.group_id"
        label="name"></v-select>
    </div>
    <div class="my-4 px-3 flex justify-between items-center">
        <p class="mr-12">Площадь</p>
        <p>{{ square }} кв.км.</p>
    </div>
    <div class="my-4 px-3 flex justify-between">
        <label>WKT <i style="color: red;">*</i></label>
        <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="WKT" v-model="district.polygon">
    </div>
    <div class="flex justify-between my-4 px-3">
        <button class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="close()">Отмена</button>
        <button class="rounded ml-2 p-1 cursor-pointer" :disabled="isActive" :class="isActive ? 'bg-black/40 text-white' : ' bg-white text-black'" @click="postDistrict">Сохранить</button>
    </div>
</div>
</template>

<script>
import converter from '@/util/converterToGeojsonOrPolygon.js'
import L from 'leaflet';
export default {
    props: ['village_id','addDistrictsData'],
    data() {
        return {
            zones: [],
            district: {
                name: '',
                group_id: null,
                polygon: null,
                lat: null,
                lng: null,
            },
            square: null,
            isActive: false
        }
    },
    watch: {
        addDistrictsData(newValue) {
            this.calculatorSquare(newValue)
            this.district.polygon = newValue.polygon[0].coordinatesPolygon.map((e) => ([e.coords[0][1], e.coords[0][0]]))
            this.district.lat = newValue.center[0]._latlng.lat
            this.district.lng = newValue.center[0]._latlng.lng
        },
        village_id(newValue) {
            if(newValue) {
                this.getZones()
            }
        }
    },
    methods: {
        calculatorSquare(newValue) {
            let erea = L.GeometryUtil.geodesicArea(newValue.polygon[0].polygonObject.getLatLngs()[0])
            this.square = erea.toFixed(0)
        },  
        getZones() {
            this.$http.get(`v1/polygons/${this.village_id}/zones`)
            .then(res => {
                this.zones = res.data
            })
        },
        postDistrict() {
            this.isActive = true
            this.district.geometry = converter.encodePolyline(this.district.polygon)
            this.$http.post(`v1/polygons/${this.village_id}/child`, this.district)
            .then(() => {
                this.$toast.open({
                    type: 'success',
                    position: 'top',
                    message: 'Района создан',
                });
                this.close()
            })
            .catch((error) => {
                this.$toast.open({
                    type: 'error',
                    position: 'top',
                    message: error,
                });
            })
            .finally(() => {
                this.isActive = false
            })
        },
        close(){
            this.closeForm()
            this.$emit('close')
        },
        closeForm() {
            this.zones = [],
            this.district = {
                name: '',
                group_id: null,
                polygon: null,
                lat: null,
                lng: null,
            },
            this.square = null
        }
    }
}
</script>