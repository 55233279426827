import axios from "@/api/axios";
export default function distance(arr) {
    const points = []
    arr.forEach(element => {
        points.push({
            lng: element.coords[1],
            lat: element.coords[0]
        })
    });
    return axios.post('distance/route', { points })
}