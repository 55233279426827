<template>
    <div>
        <div class="text-white">
            <h1 class="text-2xl px-3 font-semibold">Редактировать район</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="mr-[20px]">Название</label>
                <input @change="contrastData()" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Название" v-model="district.name">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label>Зона</label>
                <v-select
                    class="w-[270px] text-black bg-white"
                    :options="zones"
                    :reduce="zone => zone.id"
                    v-model="district.group_id"
                    @change="contrastData()"
                    label="name">
                </v-select>
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label>WKT</label>
                <input disabled class="border rounded w-[270px] text-sm text-black px-2 py-1 h-[40px]" type="text" placeholder="WKT" v-model="district.polygon">
            </div>
            <div class="flex justify-between my-4 px-3">
                <div>
                    <button class="buttonCustom" @click="$emit('cancellation')">Отмена</button>
                </div>
                <div>
                    <button class="buttonCustom mx-2" :class="block ? 'disabled' : ''" :disabled="block" @click="blockDistrict" >Заблокировать</button>
                    <button :disabled="active" :class="active ? 'disabled' : ''" class="buttonCustom" @click="updateDistrict()">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import converter from '@/util/converterToGeojsonOrPolygon.js'
export default {
    props: ['districtData','latlng', 'village_id'],
    data() {
        return {
            zones: [],
            district: {
                name: '',
                group_id: '',
                polygon: '',
                lng: '',
                lat: '',
            },
            active: true,
            block: false
        }
    },
    methods: {
       getZones() {
        this.$http.get(`v1/polygons/${this.village_id}/zones`)
            .then(res => {
                this.zones = res.data
            })
        },
        updateDistrict() {
            this.active = true
            this.district.geometry = converter.encodePolyline(this.district.polygon)
            this.$http.patch(`v1/polygons/child/${this.district.id}`, this.district)
              .then(() => {
                this.$toast.open({
                  message: "Район изменен!",
                  type: 'success',
                  position: 'top'
                });
                this.$emit('removeDistricts')
                this.$emit('getAllDistricts', this.village_id)
                this.$emit('close')
                this.clearPanelDistrictEdit()
                this.contrastData()
              })
              .catch(error => {
                this.$toast.open({
                    message: error,
                    type: 'error',
                    position: 'top'
                });
              })
              .finally(() => {
                this.active = false
              })
        },
        contrastData() {
            if(localStorage.getItem('district') === JSON.stringify(this.district)) {
                this.active = true
            }else {
                this.active = false
            }
        },
        blockDistrict() {
            this.block = true
            this.$http.put(`v1/polygons/child/${this.district.id}/block`)
            .then((res) => {
                this.$toast.open({
                    message: `Ок ${res}`,
                    type: 'success',
                    position: 'top'
                });
                this.$emit('removeDistricts')
                this.$emit('getAllDistricts', this.village_id)
                this.$emit('close')
                this.clearPanelDistrictEdit()
                this.contrastData()
            })
            .catch((error) => {
                this.$toast.open({
                    message: `Ок ${error}`,
                    type: 'error',
                    position: 'top'
                });
            })
            .finally(() => {
                this.block = false
            })
        },
        clearPanelDistrictEdit() {
            this.zones = [],
            this.district = {
                name: '',
                group_id: '',
                polygon: '',
                lng: '',
                lat: '',
            },
            this.active = true
            this.block = false
            localStorage.removeItem('district')
        }
    },
    watch: {
        latlng(newValue) {
            this.district.lat = newValue.center.lat
            this.district.lng = newValue.center.lng
            this.district.polygon = newValue.polygon
            this.contrastData()
        },
        districtData(newValue) {
            this.district = newValue
            this.district.polygon = newValue.polygon.coordinates[0]
            this.getZones()
            localStorage.setItem('district', JSON.stringify(this.district))
            this.contrastData()
        }
    }
}
</script>

<style scoped>
.buttonCustom {
    @apply border-[1px] border-white bg-white rounded-[3px] text-black p-1;
}
.disabled {
    @apply bg-black text-white border-black;
}
</style>