import Vue from 'vue';
import axios from "axios";
import checkToken from '@/util/checkToken.js'
import deleteLocalStorage from '@/util/deleteLocalStorage'

Vue.prototype.$baseURL = 'https://map.gram.tj/api/';

const api = axios.create({
    baseURL: Vue.prototype.$baseURL,
});

const token = localStorage.getItem('user-token');
if (token) {
    api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.prototype.$http = api;

checkToken(api)

api.interceptors.response.use(response => {
    if (response.data?.token) {
        localStorage.setItem('user-token', response.data.token);
        api.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('user-token');
    }
    return response;
}, (error) => {
    if (error.request.status === 401 || error.request.status === 500) {
        localStorage.removeItem('login_data')
        deleteLocalStorage()
    }
    return Promise.reject(error);
});

export default api;
