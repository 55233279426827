export default {
    data() {
        return {
            urls: [
                {
                    id: 'Mtile Gram',
                    name: 'https://mtile.gram.tj/styles/basic-preview/{z}/{x}/{y}.png'
                },
                {
                    id: 'Google',
                    name: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                },
                {
                    id: 'Google Earth',
                    name: 'https://mt2.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                },
                {
                    id: 'Yandex',
                    name: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=4.19.4&x={x}&y={y}&z={z}&scale=1&lang=ru_RU'
                },
                {
                    id: 'Yandex Hybrid',
                    name: 'https://core-sat.maps.yandex.net/tiles?l=sat&v=3.227.0.4&x={x}&y={y}&z={z}&scale=1&lang=ru_RU'
                }
            ],
        }
    }
}